import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';
import _ from 'lodash';

import { useGetUserDetails } from '../../hooks/useGetUserDetails';
import { useModal } from '../../providers/ModalProvider';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';

const User_Edit = () => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const params = useParams();
  const { t } = useTranslation();
  const { isLoading, productsInCartCounter, addresses } = useGetUserDetails();

  const [initialState, setInitialState] = useState({
    business_name: dataUser?.business_name,
    VATNUM: dataUser?.VATNUM,
    email: dataUser?.email,
    PHONE: dataUser?.PHONE,

    mainAddress: {},
    secondaryAddresses: [],
    newAddresses: [],

    address_check: false,
  });

  useEffect(() => {
    const formattedAddresses = addresses.map((a) => ({
      id: a.id,
      ISPRIMARY: a.ISPRIMARY,
      STREET: a?.STREET,
      ADDRESSNAME: a?.ADDRESSNAME,
      CITY: a.CITY,
      COUNTRYREGIONID: a.COUNTRYREGIONID,
      ZIPCODE: a.ZIPCODE,
      COUNTY: a.COUNTY,
    }));

    setInitialState((prevState) => {
      return {
        ...prevState,
        mainAddress: formattedAddresses.find((a) => a.ISPRIMARY === 'Sì') || {},
        secondaryAddresses: formattedAddresses.filter((a) => a.ISPRIMARY !== 'Sì'),
        newAddresses: [],
      };
    });
  }, [addresses]);

  const [userData, setUserData] = useState(initialState);

  useEffect(() => {
    setUserData(initialState);
  }, [initialState]);

  const handleChange = (e) => {
    const data = { ...userData };
    data[e.target.name] = e.target.value;
    setUserData(data);
  };

  const handleMainAddressChange = (e) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        mainAddress: {
          ...prevState.mainAddress,
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const handleSecondaryAddressChange = (id, e) => {
    setUserData((prevState) => {
      const secondaryAddresses = prevState.secondaryAddresses.map((a) =>
        a.id !== id ? a : { ...a, [e.target.name]: e.target.value }
      );

      return {
        ...prevState,
        secondaryAddresses,
      };
    });
  };

  const handleNewAddressChange = (index, e) => {
    setUserData((prevState) => {
      const newAddresses = prevState.newAddresses.map((a, i) =>
        index !== i ? a : { ...a, [e.target.name]: e.target.value }
      );

      return {
        ...prevState,
        newAddresses,
      };
    });
  };

  const modal = useModal();

  const handleSubmit = async (event) => {
    event.preventDefault();

    modal.showLoading({
      allowOutsideClick: false,
    });

    await axios
      .post('/v1/profile-manager-request-update', userData, {
        headers: {
          Authorization: `Bearer ${dataUser?.token}`,
        },
      })
      .then(function (response) {
        modal.showSuccess({
          subtitle: t('settings_form_success_note'),
          onConfirm: () => {
            window.location.href = '/dashboard-list';
          },
        });
      })
      .catch(function (err) {
        modal.showAttention({ subtitle: err?.response?.data?.message || t('oops_something_wrong') });
      });
  };

  const handleAddSecondaryAddressChange = (e) => {
    const newAddresses = e.target.checked
      ? [
          {
            id: null,
            ISPRIMARY: '',
            STREET: '',
            ADDRESSNAME: '',
            CITY: '',
            COUNTRYREGIONID: '',
            ZIPCODE: '',
            COUNTY: '',
          },
        ]
      : [];

    setUserData({ ...userData, newAddresses, address_check: e.target.checked });
  };

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
  }, [params?.id]);

  const disabledSubmit = useMemo(() => {
    return _.isEqual(initialState, userData);
  }, [initialState, userData]);

  //console.log('userData', userData);
  //console.log('dataUser', dataUser);
  return (
    <>
      <div className="main_content change_request">
        <div className="change_request_section">
          <div className="change_request_wrapper container">
            <div className="change_request_title">
              <h2>Richiesta modifica informazioni</h2>
              <Link to={'/dashboard-list'}>{t('Chiudi')}</Link>
              <p>Compila il seguente form per richiedere a modifica dei tuoi dati personali.</p>
            </div>
            {isLoading ? (
              <div className="loader"></div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="info_block utente_info">
                  <div className="block_title">
                    <h3>Informazioni utente</h3>
                  </div>
                  <div className="form-input">
                    <label>Ragione sociale*</label>
                    <input
                      type="text"
                      required
                      name="business_name"
                      onChange={(e) => handleChange(e)}
                      value={userData?.business_name}
                      placeholder="Ragione sociale*"
                    />
                  </div>
                  <div className="form-input">
                    <label>Partita IVA*</label>
                    <input
                      type="text"
                      required
                      name="VATNUM"
                      onChange={(e) => handleChange(e)}
                      value={userData?.VATNUM ?? dataUser?.vat_number}
                      placeholder="Partita VATNUM*"
                    />
                  </div>
                  <div className="form-input">
                    <label>Indirizzo email*</label>
                    <input
                      type="email"
                      required
                      name="email"
                      onChange={(e) => handleChange(e)}
                      value={userData?.email}
                      placeholder="Indirizzo email*"
                    />
                  </div>
                  <div className="form-input">
                    <label>Telefono*</label>
                    <input
                      type="text"
                      required
                      name="PHONE"
                      onChange={(e) => handleChange(e)}
                      value={userData?.PHONE ?? dataUser?.mobile_no}
                      placeholder="Telefono*"
                    />
                  </div>
                </div>
                {isLoading ? <div className="loader"></div> : null}
                {userData.mainAddress ? (
                  <div className="info_block principale_info">
                    <div className="block_title">
                      <h3>Indirizzo principale</h3>
                    </div>
                    <div className="form-input">
                      <label>Indirizzo*</label>
                      <input
                        type="text"
                        required
                        name="STREET"
                        onChange={(e) => handleMainAddressChange(e)}
                        value={userData.mainAddress?.STREET && userData.mainAddress.STREET.trim() !== '' ? userData.mainAddress.STREET : dataUser?.village}
                        placeholder="Indirizzo*"
                      />
                    </div>
                    <div className="form-input">
                      <label>NOMINATIVO*</label>
                      <input
                        type="text"
                        required
                        name="ADDRESSNAME"
                        onChange={(e) => handleMainAddressChange(e)}
                        value={userData.mainAddress?.ADDRESSNAME && userData.mainAddress.ADDRESSNAME.trim() !== '' ? userData.mainAddress.ADDRESSNAME : dataUser?.business_name}
                        placeholder="NOMINATIVO*"
                      />
                    </div>
                    <div className="form-input">
                      <label>Città*</label>
                      <input
                        type="text"
                        required
                        name="CITY"
                        onChange={(e) => handleMainAddressChange(e)}
                        value={userData.mainAddress?.CITY && userData.mainAddress.CITY.trim() !== '' ? userData.mainAddress.CITY : dataUser?.city}
                        placeholder="Città*"
                      />
                    </div>
                    <div className="form-input">
                      <label>Provincia*</label>
                      <input
                        type="text"
                        required
                        name="COUNTY"
                        onChange={(e) => handleMainAddressChange(e)}
                        value={userData.mainAddress?.COUNTY && userData.mainAddress.COUNTY.trim() !== '' ? userData.mainAddress.COUNTY : dataUser?.COUNTY}
                        placeholder="Provincia*"
                      />
                    </div>
                    <div className="form-input">
                      <label>CAP*</label>
                      <input
                        type="text"
                        required
                        name="ZIPCODE"
                        onChange={(e) => handleMainAddressChange(e)}
                        value={userData.mainAddress?.ZIPCODE && userData.mainAddress.ZIPCODE.trim() !== '' ? userData.mainAddress.ZIPCODE : dataUser?.postal_code}
                        placeholder="CAP*"
                      />
                    </div>
                    <div className="form-input">
                      <label>Paese*</label>
                      <input
                        type="text"
                        required
                        name="COUNTRYREGIONID"
                        onChange={(e) => handleMainAddressChange(e)}
                        value={userData.mainAddress?.COUNTRYREGIONID && userData.mainAddress.COUNTRYREGIONID.trim() !== '' ? userData.mainAddress.COUNTRYREGIONID : dataUser?.country_code}
                        placeholder="Paese*"
                      />
                    </div>
                  </div>
                ) : null}
                {userData.secondaryAddresses.length > 0 ? (
                  <>
                    <div className="info_block secondari_info">
                      <div className="block_title">
                        <h3>Indirizzi secondari</h3>
                      </div>
                    </div>
                    {userData.secondaryAddresses.map((address, index) => (
                      <div
                        className={`info_block last_info ${
                          index !== userData.secondaryAddresses.length - 1 ? 'border-bottom' : ''
                        }`}
                      >
                        <div className="form-input">
                          <label>Indirizzo*</label>
                          <input
                            type="text"
                            required
                            name="STREET"
                            onChange={(e) => handleSecondaryAddressChange(address.id, e)}
                            value={address?.STREET}
                            placeholder="Indirizzo*"
                          />
                        </div>
                        <div className="form-input">
                          <label>NOMINATIVO*</label>
                          <input
                            type="text"
                            required
                            name="ADDRESSNAME"
                            onChange={(e) => handleSecondaryAddressChange(address.id, e)}
                            value={address?.ADDRESSNAME}
                            placeholder="NOMINATIVO*"
                          />
                        </div>
                        <div className="form-input">
                          <label>Città*</label>
                          <input
                            type="text"
                            required
                            name="CITY"
                            onChange={(e) => handleSecondaryAddressChange(address.id, e)}
                            value={address?.CITY}
                            placeholder="Città*"
                          />
                        </div>
                        <div className="form-input">
                          <label>Provincia*</label>
                          <input
                            type="text"
                            required
                            name="COUNTY"
                            onChange={(e) => handleSecondaryAddressChange(address.id, e)}
                            value={address?.COUNTY ?? dataUser?.COUNTY}
                            placeholder="Provincia*"
                          />
                        </div>
                        <div className="form-input">
                          <label>CAP*</label>
                          <input
                            type="text"
                            required
                            name="ZIPCODE"
                            onChange={(e) => handleSecondaryAddressChange(address.id, e)}
                            value={address?.ZIPCODE}
                            placeholder="CAP*"
                          />
                        </div>
                        <div className="form-input">
                          <label>Paese*</label>
                          <input
                            type="text"
                            required
                            name="COUNTRYREGIONID"
                            onChange={(e) => handleSecondaryAddressChange(address.id, e)}
                            value={address?.COUNTRYREGIONID}
                            placeholder="Paese*"
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
                {!dataUser.is_prospect ? (
                  <>
                  <div className="info_block sub_address_info">
                  <div className="sub_check">
                    <input
                      checked={userData.address_check}
                      onChange={handleAddSecondaryAddressChange}
                      type="checkbox"
                      id="address_check"
                      name="address_check"
                    />
                    <label htmlFor="address_check">Aggiungi indirizzo secondario</label>
                  </div>
                </div>
                {userData.newAddresses.length > 0 ? (
                  <>
                    <div className="info_block new_address">
                      <div className="block_title">
                        <h3>Indirizzi secondari</h3>
                      </div>
                    </div>
                    {userData.newAddresses.map((address, index) => (
                      <div
                        className={`info_block last_info ${
                          index !== userData.newAddresses.length - 1 ? 'border-bottom' : ''
                        }`}
                      >
                        <div className="form-input">
                          <label>Indirizzo*</label>
                          <input
                            type="text"
                            required
                            name="STREET"
                            onChange={(e) => handleNewAddressChange(index, e)}
                            value={address?.STREET}
                            placeholder="Indirizzo*"
                          />
                        </div>
                        <div className="form-input">
                          <label>NOMINATIVO*</label>
                          <input
                            type="text"
                            required
                            name="ADDRESSNAME"
                            onChange={(e) => handleNewAddressChange(index, e)}
                            value={address?.ADDRESSNAME}
                            placeholder="NOMINATIVO*"
                          />
                        </div>
                        <div className="form-input">
                          <label>Città*</label>
                          <input
                            type="text"
                            required
                            name="CITY"
                            onChange={(e) => handleNewAddressChange(index, e)}
                            value={address?.CITY}
                            placeholder="Città*"
                          />
                        </div>
                        <div className="form-input">
                          <label>Provincia*</label>
                          <input
                            type="text"
                            required
                            name="COUNTY"
                            onChange={(e) => handleNewAddressChange(index, e)}
                            value={address?.COUNTY}
                            placeholder="Provincia*"
                          />
                        </div>
                        <div className="form-input">
                          <label>CAP*</label>
                          <input
                            type="text"
                            required
                            name="ZIPCODE"
                            onChange={(e) => handleNewAddressChange(index, e)}
                            value={address?.ZIPCODE}
                            placeholder="CAP*"
                          />
                        </div>
                        <div className="form-input">
                          <label>Paese*</label>
                          <input
                            type="text"
                            required
                            name="COUNTRYREGIONID"
                            onChange={(e) => handleNewAddressChange(index, e)}
                            value={address?.COUNTRYREGIONID}
                            placeholder="Paese*"
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
                <div className="action">
                  <PrimaryButton fullWidth disabled={disabledSubmit}>
                    Invia richiesta
                  </PrimaryButton>
                </div>
                  </>
                ) :null}
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default User_Edit;