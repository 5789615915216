import axios from 'axios';

import { UPDATE_PRODUCTS_IN_CART_COUNTER } from '../constants/ActionTypes';

export const handleMultiCartTypeError2 = (userDataDetail, modal, onCloseCallback, dispatch, t) => {
  const clearCart = () => {
    modal.showLoading({
      allowOutsideClick: false,
    });

    axios
      .post(`/v1/all-remove-from-cart`, {
        client_id: userDataDetail.id,
      })
      .then(() => {
        modal.showSuccess({});
        dispatch({
          type: UPDATE_PRODUCTS_IN_CART_COUNTER,
          productsInCartCounter: 0,
        });
      });
  };

  modal.showAttention({
    subtitle: t('messagge_product_sample_in_cart_and_not_be_added_normal_in_cart'),
    //subtitle: 'prodotto campionautra è presente nel carrello',
    confirmButtonText: 'OK',
    cancelButtonText: t('CLEAR CART'),
    hideCancelButton: false,
    onConfirm: () => onCloseCallback(),
    onCancel: () => clearCart(),
  });
};