import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import axios from 'axios';

import { useModal } from '../../providers/ModalProvider';
import { useLoader } from '../../utils/hooks';

const ChangePassword = () => {
  const { t, i18n } = useTranslation();
  const loader = useLoader({ paddingBottom: '24px' });
  const modal = useModal();
  const [errors, setErrors] = useState({});
  const search = useLocation().search;
  const location = useLocation();
  const email = location.state?.email;
  const token = new URLSearchParams(search).get('token');

  const handleSubmit = async (event) => {
    event.preventDefault();
    loader.setStartLoading();
    setErrors({});
    loader.watchPromise(
      axios
        .post('/v1/change-password', {
          token,
          email: event.target['email'].value,
          old_password: event.target['old_password'].value,
          password: event.target['new_password'].value,
          password_confirmation: event.target['confirm_new_password'].value,
        })
        .then(function (response) {
          //console.log(response.data);
          modal.showSuccess({
            subtitle: t('successfully_reset_password'),
            onConfirm: () => {
              window.location.href = '/login';
            },
          });
        })
        .catch(function (error) {
          console.log(error?.response?.data?.result?.message);
          if (error?.response?.data?.errors) {
            setErrors(error?.response?.data?.errors);
          } else {
            modal.showAttention({ subtitle: error?.response?.data?.result?.message || t('oops_something_wrong') });
          }
        })
    );
  };

  return (
    <div className="main_content account_login">
      <div className="login_section">
        <div className="login_wrapper container">
          <div className="login_title">
            <h2>{t('change-password')}</h2>
            <p>{t('change-password-subtitle')}</p>              
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-input user_field">
              <input required type="hidden" name="email" value={email} />
            </div>
            <div className="form-input user_field">
              <input required type="password" name="old_password" placeholder={t('common.old_password_required')} />
            </div>
            <div className="form-input user_field">
              <input required type="password" name="new_password" placeholder={t('common.password_required')} />
            </div>
            <div className="form-input user_field">
              <input required type="password" name="confirm_new_password" placeholder={t('common.confirm_password_required')} />
            </div>
            {errors?.password?.length ? errors?.password.map((e) => <div className="form-input error">{e}</div>) : null}
            <div className="action">{loader.asJsx || <button type="submit">{t('Continue')}</button>}</div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
