import { React } from 'react';

import { BIOFIL_YARNTYPEID, GOTS_YARNTYPEID, OCS_YARNTYPEID } from '../../../../constants/certificates';

const CertificateIcon = ({ YARNTYPEID }) => {
  return YARNTYPEID === BIOFIL_YARNTYPEID ? (
    <img style={{ height: '20px', margin: '0 5px' }} src="/images/certificate-icons/biofil.png" alt="" />
  ) : YARNTYPEID === GOTS_YARNTYPEID ? (
    <img style={{ height: '20px', margin: '0 5px' }} src="/images/certificate-icons/gots.png" alt="" />
  ) : YARNTYPEID === OCS_YARNTYPEID ? (
    <img style={{ height: '20px', margin: '0 5px' }} src="/images/certificate-icons/ocs.png" alt="" />
  ) : null;
};

export default CertificateIcon;